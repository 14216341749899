
import {mapActions, mapMutations, mapState} from "vuex";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Jitsi from "@/components/Jitsi.vue";
import {IResult} from "@/schemas/IResult";
import {IMessage} from "@/schemas/IMessage";
import {IUser} from "@/schemas/IUser";
import {randomColor} from "@/utils";
import WebSocketManager from "@/utils/websocketManager";
import ChatBubble from "@/components/task/common/chat/ChatBubble.vue";
import {webSocketUrl} from "@/env";
import {NavigationGuardNext, Route} from "vue-router";
import {assetsUrl} from "@/env";
import clientCache from "@/utils/cacheUtils";
//import contenteditableDirective from 'vue-contenteditable-directive'
// import imageSrc from '@/assets/chat_emojis/emoji_joie_gris.svg';

@Component({
  name: "ChatRoom",
  components: {ChatBubble, Jitsi},
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("chatroom", ["messages"]),
    ...mapState("session", ["session", "users"]),
  },
  methods: {
    ...mapActions("chatroom", ["fetchMessages"]),
    ...mapMutations("chatroom", {
      addMessage: "ADD_MESSAGE",
    }),
    ...mapMutations({setSnack: "SET_SNACK"}),
  },
})

export default class ChatRoom extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly sessionId!: string;

  @Prop({type: Boolean, required: false, default: false})
  displayHighlightNotification: boolean | undefined;

  chatWS: WebSocketManager | null = null;


  private scrollInvoked = 1000;
  message: string | null = null;
  lastMessage: { content?: string; author_id?: string } = {};
  userId: string | undefined;
  userNickname: string | undefined;
  messages: IMessage[] | undefined;
  textArea: any;
  cursorPosition = null; // range object
  showEmoji = false;
  emoji_text = [":bras:", ":colere:", ":degout:", ":fete:", ":hand:", ":joie:", ":peur:", ":pouce_bas:", ":pouce_haut:", ":priere:", ":reflexion:", ":rire:", ":sablier:", ":salut:", ":surprise:", ":trist:"];
  emoji_ico_prefixe = '<img class="emoji_room_img" src="/assets/chat_emojis/';
  emoji_ico_suffixe = '">';
  nickname = ''
  heartbeatId = 0;
  lastHeartbeatId = 0;
  interval = null;

  fetchMessages: any;
  addMessage: any;
  session: any;
  results: IResult[] | undefined;
  fullyLoaded = false;
  nickChanged = false;

  data(): any {
    return {
      nickname:"",
      dialog: "",
      nickChanged: false,
      chatfocus: false,


    }
  }


  changeNick(): void {
    this.userNickname=this.nickname
    this.nickChanged = true;
    this.dialog = false;
    clientCache.cache(`has_changed_nick`, true);
    clientCache.cache(`nickname`, this.userNickname);
  }

  toggleEmoji() {
    if(this.chatfocus==false){
      this.$refs.chatInput.focus();
      this.chatfocus=true
      this.saveCursorPosition()
    }




    this.showEmoji = !this.showEmoji;
    return this.showEmoji;
  }

  hideEmoji(): void {
    this.showEmoji = false;
  }

  onFocusChatInput(): void {
    this.$refs.placeholder.classList.add('v-label--active','primary--text')
    this.chatfocus=true


  }

  onBlurChatInput(): void {

    if(this.$refs.chatInput.innerHTML == '' || this.$refs.chatInput.innerHTML == '<br>' ){
      this.$refs.placeholder.classList.remove('v-label--active','primary--text');
    }


  }


  getEmojiPathFromText(emoji_text: string): string {
    // const emoji_ico_prefixe = '<div class="emoji_room_img"><img src="/assets/chat_emojis/';
    // const emoji_ico_suffixe = '"></div>;
    const emoji_ico = `emoji_${emoji_text.slice(1).slice(0,-1)}.svg`;
    const emoji_ico_prefixe_with_id = `${this.emoji_ico_prefixe.slice(0,4)} id="${emoji_text}" ${this.emoji_ico_prefixe.slice(4,)}`;
    return emoji_ico_prefixe_with_id + emoji_ico + this.emoji_ico_suffixe;
  }


  insertEmojiTextArea(input): void {

    const truc = input;
    const img = this.getEmojiPathFromText(input);


      let range = this.cursorPosition
      let node = range.createContextualFragment(img);
      range.insertNode(node);


    this.toggleEmoji();
    this.$refs.chatInput.focus();
    this.$refs.chatInput.selectionStart = this.$refs.chatInput.innerHTML.length;

    document.execCommand('selectAll', false, null);
    document.getSelection().collapseToEnd();
    this.saveCursorPosition()

  }

  getImageSrc(slug: string): string {
    return `${assetsUrl}chat_emojis/${slug}`;
  }

  getEmoji(emoji_text: string): string {
    // const ee = emo.slice(1);
    const emoji_ico = `emoji_${emoji_text.slice(1).slice(0,-1)}.svg`;
    return this.getImageSrc(emoji_ico);
  }

  saveCursorPosition(): void {

    let range = window.getSelection().getRangeAt(0);

    this.cursorPosition = range;
  }



  created(): void {
    // We need the results to know the user colors
    this.$store.dispatch("session/getSessionResults", {
      sessionId: this.sessionId,
      next: (results: IResult[]) => {
        this.results = results;
        this.fullyLoaded = true;



        // this.userNickname=this.userId
      },
    });

  }

  connectChatWS(): void {
    let chatWSUrl = `${webSocketUrl}/chat/${this.sessionId}/${this.userId}`;
    this.chatWS = new WebSocketManager(
        chatWSUrl,
        this.handleIncomingMessage,
        this.onRetryFail,
        undefined,
        this.onConnect
    );

  }

  onConnect(): void {
    console.log("Successfully connected to the chat.");

    this.interval = setInterval(() => {
      if (this.heartbeatId - this.lastHeartbeatId > 3) {
        console.log("Closing the chat WS due to inactivity...");
        //this.chatWS?.close(1000, "No heartbeat sent in the last 3 seconds");
        this.setSnack({
          snackText:
              "La connexion WebSocket du chat a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
          snackColor: "error",
          snackClosable: true,
        });
        this.lastHeartbeatId = this.heartbeatId;

        window.clearInterval(this.interval)
        setTimeout(() => {
          this.connectChatWS();
        }, 1000);
      }

      this.chatWS?.send(
          {
            type: "heartbeat",
            heartbeat_id: this.heartbeatId
          },
          () => {
            console.log("Error while sending heartbeat to the chat WS");
          }
      );
      this.heartbeatId++;
    }, 1000);
  }

  onRetryFail(event: CloseEvent): void {
    // Alert user and provide a user-friendly message
    this.setSnack({
      snackText:
          "La connexion WebSocket du chat a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
      snackColor: "error",
      snackClosable: true,
    });
  }

  replaceEmojiTextToEmojiIco(message:string): string {
    const replacedString = message.replace(
        new RegExp(this.emoji_text.join('|'), 'gi'),
        (match) => {
          const index = this.emoji_text.indexOf(match.toLowerCase());
          const emoji_ico = `emoji_${this.emoji_text[index].slice(1).slice(0,-1)}.svg`;
          return `${this.emoji_ico_prefixe}${emoji_ico}${this.emoji_ico_suffixe}`;
        }
    );
    return replacedString;
  }

  get chatMessages(): IMessage[] {

    const message_emojis: [] = [];
    let i=0
    for (let message: IMessage of this.messages) {
      i=i+1
      const message_ct = message.content;
      const emoji = this.replaceEmojiTextToEmojiIco(message_ct);
      message.content = emoji;
    }

    // this.messages.forEach((message, index) => {
    //
    // });


    return this.messages || [];
  }


  get messageNumber(): number {
    return this.chatMessages.length;
  }

  // Get the scrollable ref
  get scrollable(): Vue & {
    childElementCount: number;
    scrollTo: CallableFunction;
    scrollHeight: number;
  } {
    return this.$refs.scrollable as Vue & {
      childElementCount: number;
      scrollTo: () => undefined;
      scrollHeight: number;
    };
  }

  updated(): void {
    if (this.messageNumber < this.scrollable?.childElementCount) {
      this.scrollable.scrollTo({
        top: this.scrollable.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  mounted(): void {
    this.$refs.chatInput?.addEventListener("input", console.log("mounted"))
    if (this.sessionId) {
      this.fetchMessages(this.sessionId);
      this.connectChatWS();
      this.textArea = this.$refs.chatInput;


    }
    let changed = clientCache.get("has_changed_nick")
    if(changed == "true"){

      this.nickChanged = true;

      this.userNickname = clientCache.get("nickname")}
    else{this.nickChanged = false;}

  }

  beforeDestroy(): void {
    this.closeWS();
    if (this.interval) {
      window.clearInterval(this.interval)
    }
  }

  deactivated(): void {
    this.closeWS();
  }

  // Handles when the scroll of scrollable HTMLElement change
  onScroll(): void {
    this.scrollInvoked++;
  }

  // Handles when a message is received on the websocket channel
  handleIncomingMessage(message: {
    message: { content: any; author_id: any };
  }): void {
    const msg: any = message;
    if (msg.type === "heartbeat") {
      this.lastHeartbeatId = msg.heartbeat_id;
      return;
    }


    if (this.lastMessage) {
      if (this.lastMessage.content === message.message.content) {
        if (this.lastMessage.author_id === message.message.author_id) {
          return;
        }
      }
    }
    this.addMessage(message.message);
    this.$nextTick(() => {
      this.scroll();
    });
    this.lastMessage = message.message;
  }

  clearMessage(): void {
    this.$refs.chatInput.innerHTML = null;
  }

  // Send a message on the websocket channel
  sendMessage(): void {
    // on garde l'existant

    this.message = this.$refs.chatInput.innerHTML;
    const parser = new DOMParser();
    // const htmlString = '<img id="pouce_bas" class="emoji_room_img" src="/assets/chat_emojis/emoji_pouce_bas.svg"><img id="fete" class="emoji_room_img" src="/assets/chat_emojis/emoji_fete.svg">';
    const htmlString = this.$refs.chatInput.innerHTML;
    const doc = parser.parseFromString(htmlString, "text/html");

    doc.querySelectorAll("img").forEach(el => {
      el.replaceWith(el.getAttribute("id"));
    })

    const contenu = doc.body.innerHTML

    //Prevent from sending empty messages
    if (contenu && !contenu.match(/^\n+$|^\s+$/g)) {
      let message = {
        session_id: this.sessionId,
        author_id: this.userId,
        content: contenu,
        type: "text",
        author_nickname: this.userNickname
      };

      this.chatWS.send({message, type: "message"}, () =>
          this.onSendMessageError(message)
      );
      this.clearMessage();
    }
  }

  /**
   * Callback triggered when there's an issue while sending message
   * @param message sent message
   */
  onSendMessageError(message: {
    session_id: string;
    author_id: string | undefined;
    content: string;
    type: string;
  }): void {
    this.setSnack({
      snackText: "Une erreur s'est produite lors de l'envoi du message",
      snackColor: "error",
      snackClosable: true,
    });
  }

  // Retrieves users names
  getUserName(message: IMessage): string {
    if(message.author_nickname){
      return message.author_nickname}
    else{
    return this.users.find((user: IUser) => user.id === message.author_id)?.username || "";}
  }

  onKeyPressed(event: KeyboardEvent): void {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  getUserColor(userId: string): string {
    if (this.results) {
      let userResult = this.results.find(
          (res: IResult) => res.user_id == userId
      );
      return (
          userResult?.color ||
          randomColor(
              this.users.findIndex((user: IUser) => user.id === userId)
          ) ||
          ""
      );
    }
    return (
        randomColor(this.users.findIndex((user: IUser) => user.id === userId)) ||
        ""
    );
  }

  scroll(): void {
    this.scrollable.$el.scrollTop = this.scrollable.$el.scrollHeight;
  }

  closeWS(): void {
    this.chatWS.close(1000, "Websocket connection normally closed by the app");
  }

  // clearWS(): void {
  //   this.chatWS.close("3333", "click on chat btn");
  // }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.interval) {
      window.clearInterval(this.interval)
    }
    next();
  }
}
